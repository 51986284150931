.Slider-Buttons-Container {
	margin: auto;
	top: 50%;
	left: 0;
	bottom: 50%;
	right: 0;
	position: absolute;
	display: inline-block;
	z-index: 1;
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}
.Slider-Button-Container {
	display: inline-block;
	z-index: 1;
	width: 50%;
}
.Slider-Button {
	width: 45px;
	height: 45px;
	background-color: white;
	margin: 10px;
	border: 0px;
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	   -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-border-radius: 22.5px;
	   -moz-border-radius: 22.5px;
	        border-radius: 22.5px;
}
.Slider-Button-Left {
	float: left;
}
.Slider-Button-Right {
	float: right;
}
.Slider-Button:focus {
	outline: 0px;
}
.Slider-Button:active {
	background-color: #f4f4f4;
}
.Slider-Button-Icon {
	margin: auto;
	display: block;
	width: 13px;
}
@media only screen and (max-width: 600px) {
	.Slider-Button {
		width: 35px;
		height: 35px;
	}
	.Slider-Button-Icon {
		width: 11px;
	}
}
