@-webkit-keyframes Slider-Slide-CurrentlySelected {
	0% {
		background-color: "white";
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		background-color: #f4d442;
		-webkit-transform: scale(1.2);
		        transform: scale(1.2);
	}
}

@-moz-keyframes Slider-Slide-CurrentlySelected {
	0% {
		background-color: "white";
		-moz-transform: scale(1);
		     transform: scale(1);
	}
	100% {
		background-color: #f4d442;
		-moz-transform: scale(1.2);
		     transform: scale(1.2);
	}
}

@-o-keyframes Slider-Slide-CurrentlySelected {
	0% {
		background-color: "white";
		-o-transform: scale(1);
		   transform: scale(1);
	}
	100% {
		background-color: #f4d442;
		-o-transform: scale(1.2);
		   transform: scale(1.2);
	}
}

@keyframes Slider-Slide-CurrentlySelected {
	0% {
		background-color: "white";
		-webkit-transform: scale(1);
		   -moz-transform: scale(1);
		     -o-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		background-color: #f4d442;
		-webkit-transform: scale(1.2);
		   -moz-transform: scale(1.2);
		     -o-transform: scale(1.2);
		        transform: scale(1.2);
	}
}

@-webkit-keyframes Slider-Slide-CurrentlyNotSelected {
	100% {
		background-color: "white";
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@-moz-keyframes Slider-Slide-CurrentlyNotSelected {
	100% {
		background-color: "white";
		-moz-transform: scale(1);
		     transform: scale(1);
	}
}

@-o-keyframes Slider-Slide-CurrentlyNotSelected {
	100% {
		background-color: "white";
		-o-transform: scale(1);
		   transform: scale(1);
	}
}

@keyframes Slider-Slide-CurrentlyNotSelected {
	100% {
		background-color: "white";
		-webkit-transform: scale(1);
		   -moz-transform: scale(1);
		     -o-transform: scale(1);
		        transform: scale(1);
	}
}

.Slider-Slide-Selector {
	width: 25px;
	height: 25px;
	background-color: white;
	-webkit-border-radius: 12.5px;
	   -moz-border-radius: 12.5px;
	        border-radius: 12.5px;
	border: 0px;
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	   -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Slider-Slide-Selector-Default-Animation {
	-webkit-animation-duration: 0.25s;
	   -moz-animation-duration: 0.25s;
	     -o-animation-duration: 0.25s;
	        animation-duration: 0.25s;
	-webkit-animation-fill-mode: forwards;
	   -moz-animation-fill-mode: forwards;
	     -o-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.Slider-Slide-Selector:focus {
	outline: 0px;
}

@media only screen and (max-width: 600px) {
	.Slider-Slide-Selector {
		width: 20px;
		height: 20px;
		-webkit-border-radius: 10px;
		   -moz-border-radius: 10px;
		        border-radius: 10px;
	}
}
